import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { v4 as uuid } from 'uuid';
import API from '../../api/api';
import linkState from 'linkstate';
import './admin-services.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const garageApi = new API();

class AdminServicesDialog extends Component {

  constructor(props) {
    super(props);

    this.isAddMode = true;

    this.state = {
      service: {
        unikey: '',
        title: '',
        description: '',
        text: '',
        image: 0,
      },
      loading: true,
      selectedFile: null,
      upload: 'Bild Hochladen',
      snackBar: false,
    };

    if(Object.keys(props.servicesData).length >=1)
    {
      this.isAddMode = false;
      this.state.service = props.servicesData;
      this.state.loading = false;
    }
    else
    {
      this.state.service.unikey = uuid();
      this.state.loading = false;
    }
  }

  uploadFile = (event) => {

    const formData = new FormData();

    // Update the formData object
    formData.append("image", event.target.files[0]);
    formData.append('type', 'service');
    formData.append('name', 'Service');
    formData.append('unikey', uuid());

    garageApi.endpoints.image.create(formData)
      .then((getRequest) => {
        try
        { // eslint-disable-next-line
          this.state.service.image = getRequest.data.id;
          this.setState({ upload: event.target.files[0].name})
        }
        catch(e)
        {
          console.log(e);
        }
      });
  };

  updateText(text)
  { // eslint-disable-next-line
    this.state.service.text = text;
    this.setState({ loading: false });
  }

  submitDialog()
  {
    var serviceData = this.state.service;

    if( serviceData.title === '' || serviceData.description === '' || serviceData.text === '' || serviceData.image ===  0)
    {
      this.setState({ snackBar: true, });
    }
    else
    {
      this.props.submit(this.isAddMode,serviceData);
    }
  }

  closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackBar: false, });
  }


  render() {
    return (
      <div>
        <DialogTitle id="form-dialog-title">Service { this.isAddMode ? "Erstellen" : "Bearbeiten" }</DialogTitle>
        { this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        <DialogContent>
          <div className="ser-line-one">
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Titel"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.service.title}
              onInput={linkState(this, 'service.title')}
            />
          </div>
          <div className="ser-line-one">
            <TextField
              margin="dense"
              id="description"
              multiline
              rows={4}
              label="Beschreibung"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.service.description}
              onInput={linkState(this, 'service.description')}
            />
          </div>
          <div className="ser-line-two">
            <ReactQuill value={this.state.service.text} onChange={this.updateText.bind(this)} />
          </div>
          <div className="pt-line-three">
            <span></span>
            <div className="image-upload">
              <div className="image-upload-display">{this.state.upload}</div>
              <input className="image-upload-input" type="file" onChange={this.uploadFile} />
            </div>
            <span></span>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="p-line-end">
            <Button variant="contained" onClick={this.props.close}>
              Abbrechen
            </Button>
            <span></span>
            <Button variant="contained" color="primary" onClick={() => { this.submitDialog() }}>
              Speichern
            </Button>
          </div>
        </DialogActions>
        <Snackbar open={this.state.snackBar} autoHideDuration={6000} onClose={this.closeSnackBar.bind(this)}>
            <MuiAlert elevation={6} variant="filled" onClose={this.closeSnackBar.bind(this)} severity="warning" >
              Alle Felder müssen ausgefüllt sein!
            </MuiAlert>
          </Snackbar>
      </div>
    );
  }
}

export default AdminServicesDialog;
