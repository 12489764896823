import React from "react";
// @material-ui/icons
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from "react-router-dom";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";

export default function PostArea(props) {
  const { title, description, image, unikey } = props;

  return (
    <div className="post-area">
      <div className="post-area-image-wrapper">
        { (typeof(image) === 'object') ?
          image
          :
          <>
            <img className="post-area-image" src={image} alt={title}/>
            <div className="post-area-image-cover" style={{ backgroundImage: `url(${image})`, }}></div>
          </>
        }
      </div>
      <div className="post-area-description-wrapper">
        <h4 className="post-area-title">{title}</h4>
        <p className="post-area-description">{description}</p>
      </div>
      <div className="post-area-button-wrapper">
        <span></span>
        <Button component={RouterLink} to={`/nachrichten/${unikey}/${title.toString().replace(/\W/g, '')}.html`} style={{ fontWeight:'bold', color:'#14a0db' }}>Weiterlesen</Button>
        <span></span>
      </div>
    </div>
  );
}

export function PostExtendedArea(props) {
  const { title, description, image, unikey, index } = props;

  return (
    <div className={ (index % 2) ? "post-extended-area turn-post" : "post-extended-area"}>
      <GridContainer justify="center">
        { !(index % 2) ?

          <GridItem xs={12} sm={6} md={4}>
            <div className="post-extended-area-image-wrapper">
              { (typeof(image) === 'object') ?
                image
                :
                <>
                  <img className="post-extended-area-image" src={image} alt={title}/>
                  <div className="post-extended-area-image-cover" style={{ backgroundImage: `url(${image})`, }}></div>
                </>
              }
            </div>
          </GridItem>
          :
          ''
        }
        <GridItem xs={12} sm={6} md={4}>
          <div className="post-area-description-wrapper">
            <h4 className="post-extended-area-title">{title}</h4>
            <p className="post-area-description">{description}</p>
          </div>
          <div className="post-extended-area-button-wrapper">
            <Button component={RouterLink} to={`/nachrichten/${unikey}/${title.toString().replace(/\W/g, '')}.html`} style={{ fontWeight:'bold', color:'#14a0db' }}>Weiterlesen</Button>
            <span></span>
            <span></span>
          </div>
        </GridItem>
        { (index % 2) ?

          <GridItem xs={12} sm={6} md={4}>
            <div className="post-extended-area-image-wrapper">
              <img className="post-extended-area-image" src={image} alt={title}/>
              <div className="post-extended-area-image-cover" style={{ backgroundImage: `url(${image})`, }}></div>
            </div>
          </GridItem>
          :
          ''
        }
      </GridContainer>
    </div>
  );
}
