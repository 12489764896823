import React from 'react';
import SideBar from '../../../components/AdminBar/SideBar';
import TopBar from '../../../components/AdminBar/TopBar';
import CustomScrollbars from '../../../components/ScrollBar/ScrollBar';
import TitleBar from '../../../components/AdminBar/TitleBar';
import { Link as RouterLink } from 'react-router-dom'
import { Breadcrumbs, Link } from '@material-ui/core'

const SecuredZone = (props) => {

  var jVtips = document.getElementsByClassName('jvectormap-tip');

  for (var i = 0; i < jVtips.length; i++)
  {
    jVtips[i].remove();
  }

  return (
    <>
      <div className="main-screen">
        <div className="main-header">
          <TopBar/>
        </div>
        <div className="main-content">
          <div className="main-menu">
            <SideBar/>
          </div>
          <div className="main-page">
            {props.loading}
            <div>
            <div className="bread-crumbs">
            { props.breadcrumbs ?

              <Breadcrumbs separator="›" aria-label="breadcrumb">
                { Object.entries(props.breadcrumbs).map((item, index) => {

                  return (
                    <Link color="inherit" component={RouterLink} to={item[0]}>
                      {item[1]}
                    </Link>
                  )}
                )}
              </Breadcrumbs>
               :
              ''
            }
            </div>
            <TitleBar menu={props.menu} text={props.title}/>
            <div className="main-page-content">
              <CustomScrollbars style={{ height: '100%' }}>
              {props.children}
              </CustomScrollbars>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecuredZone;
