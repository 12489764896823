import React from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import OpeningHoursArea from "../../../components/OpeningHours/OpeningHoursArea.js";
//api

export default function ServiceSection(props) {

  var openingHours = props.openingHours;

  var sortedOpeningHours = [];
  var printOpeningHoursArray = [];

  const sorter = {
    "mon": 1,
    "tue": 2,
    "wed": 3,
    "thu": 4,
    "fri": 5,
    "sat": 6,
    "sun": 7
  }

  var dayKeys = Object.keys(openingHours);
  var len = dayKeys.length;

  dayKeys.sort(function sortByDay(a, b) {
    let day1 = a.toLowerCase();
    let day2 = b.toLowerCase();
    return sorter[day1] - sorter[day2];
  });

  for (var i = 0; i < len; i++) {
    var k = dayKeys[i];
    sortedOpeningHours[k] = openingHours[k];
  }

  Object.keys(sortedOpeningHours).map(function(day, index)
  {
    var isSomewhereTrue = false;

    for (var i = 0; i < printOpeningHoursArray.length; i++)
    {
      if( JSON.stringify(printOpeningHoursArray[i].opening) === JSON.stringify(openingHours[day])
          && (sorter[day] - 1) === sorter[printOpeningHoursArray[i].days[printOpeningHoursArray[i].days.length-1]])
      {
        printOpeningHoursArray[i].days.push(day);
        isSomewhereTrue = true;
      }
    }

    if(isSomewhereTrue === false)
    {
      var tempArray = {
        opening: {},
        days: [],
      }

      tempArray.opening = openingHours[day];
      tempArray.days.push(day);

      printOpeningHoursArray.push(tempArray)
    }

    return true;
  });

  return (
    <>
      <div className="grid-section">

        <div className="mouse-scroll">
          <div className="mouse">
            <div className="mouse-in"></div>
          </div>
          <div>
              <span className="down-arrow-1"></span>
              <span className="down-arrow-2"></span>
              <span className="down-arrow-3"></span>
           </div>
        </div>

        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={6}>
            <h2 className="grid-title">{props.homeWelcomeTitle}</h2>
            <h5 className="grid-description" dangerouslySetInnerHTML={{__html: props.homeWelcomeText}}></h5>
          </GridItem>
        </GridContainer>
      </div>

      <div className="grid-section">
        <h2 style={{ marginBottom: '60px' }} className="grid-title">Öffnungszeiten</h2>
        <GridContainer>
          { printOpeningHoursArray.map((openingHours, index) => {
            return (
              <GridItem xs={12} sm={6} md={12 / printOpeningHoursArray.length}>
                <OpeningHoursArea
                    key={index}
                    openingHours={openingHours}
                />
                { printOpeningHoursArray.length === (index + 1) ?
                  null
                  :
                  <div className="opening-break"></div>
                }
              </GridItem>
            )
           })}
        </GridContainer>
      </div>
    </>
  );
}
