import React from "react";
// nodejs library to set properties for components

function convertToTime(number) {
    // Check sign of given number
    var sign = (number >= 0) ? 1 : -1;
    // Set positive value of number of sign negative
    number = number * sign;
    // Separate the int from the decimal part
    var hour = Math.floor(number);
    var decpart = number - hour;
    var min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);
    var minute = Math.floor(decpart * 60) + '';
    // Add padding if need
    if (minute.length < 2) {
    minute = '0' + minute;
    }
    // Add Sign in final result
    sign = sign === 1 ? '' : '-';
    // Concate hours and minutes
    var time = sign + hour + ':' + minute;

    return time;
}

export default function ServiceArea(props) {

  var days = props.openingHours.days;
  var opening = props.openingHours.opening;

  const sorter = {
    "mon": 1,
    "tue": 2,
    "wed": 3,
    "thu": 4,
    "fri": 5,
    "sat": 6,
    "sun": 7
  }

  days.sort(function sortByDay(a, b) {
    let day1 = a.toLowerCase();
    let day2 = b.toLowerCase();
    return sorter[day1] - sorter[day2];
  });

  var dayNames = {
    mon: "Montag",
    tue: "Dienstag",
    wed: "Mittwoch",
    thu: "Donnerstag",
    fri: "Freitag",
    sat: "Samstag",
    sun: "Sonntag"
  };

  let weekday = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][new Date().getDay()]

  return (
    <div className="opening-hours-area">
      <div className="opening-hours-time-wrapper">
      {
        opening.map((openingTimes, index) => {

          return (
            <>
              <div style={{ color:'#2d7b17', }}  className="opening-hours-time">
              {convertToTime(openingTimes[0])} - {convertToTime(openingTimes[1]  - 0.5)}

              </div>
              { opening.length === (index + 1) ?
                null
                :
                <div className="opening-hours-time-and">und</div>
              }
            </>
          )

        })
      }
      { opening.length === 0 ?
        <div style={{ color:'#f76300', }}  className="opening-hours-time">
        Geschlossen
        </div>
        :
        null
      }
      </div>
      <div className="opening-hours-break"></div>
      <div className="opening-hours-name-wrapper">
        {
          days.map(function(dayName, index){
            return (
              <div style={{ fontFamily: (weekday === dayName ? 'TitilliumBold' : 'Titillium' ) }} className="opening-hours-name">
              {dayNames[dayName]}
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
