import React from 'react';
import SecuredZone from '../SecuredZone/SecuredZone';
import API from '../../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { CollapseAppointment, CollapseContact } from './CollapsRow.js';

const garageApi = new API();

/*{moment(row.created).format('DD.MM.YYYY HH:mm')}*/

class AdminForms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingAppointments: true,
      appointments: [],
      appointmentsPage: 0,
      appointmentsPageLength: 0,

      loadingContacts: true,
      contacts: [],
      contactsPage: 0,
      contactsPageLength: 0,
    };
  }

  componentDidMount()
  {
    this.loadAppointments(0);
    this.loadContacts(0);
  }

  loadAppointments(value)
  {
    garageApi.endpoints.formAppointments.getPage( { get: { limit:10, offset:(((value) * 10)) }  } )
      .then((postsGET) => {
        try
        {
          this.setState({ appointments:  postsGET.data.results });
          this.setState( { appointmentsPageLength: postsGET.data.count })
          this.setState({ loadingAppointments: false });
        }
        catch(e)
        {
          console.log(e);
        }

      });
  }

  loadContacts(value)
  {
    garageApi.endpoints.formContacts.getPage( { get: { limit:10, offset:(((value) * 10)) }  } )
      .then((postsGET) => {
        try
        {
          this.setState({ contacts:  postsGET.data.results });
          this.setState( { contactsPageLength: postsGET.data.count })
          this.setState({ loadingContacts: false });
        }
        catch(e)
        {
          console.log(e);
        }

      });
  }

  deleteAppointment(unikey)
  {
    var deleteAppointment = this.state.appointments.find((element) => { return element.unikey === unikey; })

    this.setState({ loadingAppointments: true });

    garageApi.endpoints.formAppointments.delete( {id: deleteAppointment.id })
      .then((getRequest) => {
        try
        {
          this.loadAppointments();
        }
        catch(e)
        {
          console.log(e);
        }
      });
  }

  deleteContact(unikey)
  {
    var deleteContact = this.state.contacts.find((element) => { return element.unikey === unikey; })

    this.setState({ loadingContacts: true });

    garageApi.endpoints.formContacts.delete( {id: deleteContact.id })
      .then((getRequest) => {
        try
        {
          this.loadContacts();
        }
        catch(e)
        {
          console.log(e);
        }
      });
  }

  changeAppointmentsPage(event, newPage) {
    this.setState({ appointmentsPage: newPage });
    this.loadAppointments(newPage);
  };

  changeContactsPage(event, newPage) {
    this.setState({ contactsPage: newPage });
    this.loadContacts(newPage);
  };

  render() {
    return (
      <SecuredZone
        breadcrumbs={{ '/admin/': 'Dashboard','/admin/forms': 'Formulare' }}
        title="Online Termin"
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
      >
        { this.state.loadingAppointments ?
          <span></span>
          :
          <>
            <div className="settings-title">Online Termine</div>
            <TableContainer className="settings-table" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Auto</TableCell>
                    <TableCell>E-Mail Adresse</TableCell>
                    <TableCell>Telefonnumer</TableCell>
                    <TableCell>Erstellt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { Array.isArray(this.state.appointments) ?
                    <>
                      {this.state.appointments.map((row) => (
                        <CollapseAppointment row={row}></CollapseAppointment>
                      ))}
                    </>
                    :
                    <>
                    </>
                  }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      rowsPerPage={10}
                      count={this.state.appointmentsPageLength}
                      page={this.state.appointmentsPage}
                      onChangePage={this.changeAppointmentsPage.bind(this)}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </>
        }
        { this.state.loadingContacts ?
          <span></span>
          :
          <div style={{ marginTop: '60px', marginBottom: '60px' }}>
            <div className="settings-title">Kontakt Formular</div>
            <TableContainer className="settings-table" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>E-Mail Adresse</TableCell>
                    <TableCell>Erstellt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { Array.isArray(this.state.contacts) ?
                    <>
                      {this.state.contacts.map((row) => (
                        <CollapseContact row={row}></CollapseContact>
                      ))}
                    </>
                    :
                    <>
                    </>
                  }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      rowsPerPage={10}
                      count={this.state.contactsPageLength}
                      page={this.state.contactsPage}
                      onChangePage={this.changeContactsPage.bind(this)}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        }
      </SecuredZone>
    );
  }
}

export default AdminForms;
