import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import TimeCell from "./TimeCell";
import { getOpeningHoursSelectedRanges } from "./util";
import IconButton from '@material-ui/core/IconButton';
import RestoreIcon from '@material-ui/icons/Restore';

class OpeningHoursDay extends React.Component {
  render() {
    const num = this.props.timeCells.length;
    const timeCells = this.props.timeCells;

    // render half-hour time cells
    const timeCellElements = _.range(num).map(i => (
      <TimeCell
        key={`timeCell-${timeCells[i].id}`}
        ref={input => {
          if (input == null) return;
          timeCells[input.props.state.index].elementRef = input;
        }}
        state={timeCells[i]}
        startSelect={this.props.startSelect}
      />
    ));

    // calculate selected ranges
    const ranges = getOpeningHoursSelectedRanges(timeCells);

    const rangesObject = _.map(ranges, r => {
      const tmp = [(r.start / 2), (r.end / 2 + 0.5)]
      return tmp;
    });

    if(rangesObject ===  this.props.data)
    {

    }
    else
    {
      this.props.updateSelect(this.props.giveKey,rangesObject);
    }

    // humanize the timespan ranges
    /*
    const rangeStr = _.map(ranges, r => {
      const tmp = [timeCells[r.start].timeFrom, timeCells[r.end].timeTo]; //.replace(/:00$/, '')
      return tmp.join("-");
    }).join(", ");*/

    return (
      <tr className="day">
        <td className="day-name">
          {this.props.name}
        </td>
        {timeCellElements}
        <td className="reset">
          <IconButton onClick={e => {
            this.props.resetDay(e, this.props.index);
          }} aria-label="Zurücksetzen"   className="btn btn-primary btn-xs working-hours-reset" size="small">
            <RestoreIcon fontSize="inherit" />
          </IconButton>
        </td>
      </tr>
    );
  }
}

OpeningHoursDay.propTypes = {
  giveKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  updateSelect: PropTypes.func,
  timeCells: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  startSelect: PropTypes.func.isRequired,
  resetDay: PropTypes.func.isRequired,
};

export default OpeningHoursDay;
