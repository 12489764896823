import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { ParallaxBanner } from 'react-scroll-parallax';
import ParallaxCache from "../../components/Parallax/ParallaxCache";
import PopOutInfo from "../../components/PopOutInfo/PopOutInfo";
import TypeWriterEffect from 'react-typewriter-effect';
import { useLocation } from 'react-router-dom'
import StartSection from "./Sections/StartSection";
import ServiceSection from "./Sections/ServiceSection";
import PostSection from "./Sections/PostSection";
import ContactSection from "./Sections/ContactSection";
import {Helmet} from "react-helmet";

export default function HomePage(props) {
  const location = useLocation();
  const { configuration } = props;

  return (
    <div>
      <Helmet>
          <title>Matta-Carrosserie - Startseite</title>
      </Helmet>
      <PopOutInfo
        openingHours={configuration.openingHours}
        mapsLink={configuration.mapsLink}
        mapsLinkText={configuration.mapsLinkText}
        popUpcontactText={configuration.popUpcontactText}
      />
      <Header
        color="transparent"
        fixed
        currentLocation={location.pathname}
      />
      <div className="page">
        <ParallaxBanner
            className="open-parralax-container"
            layers={[
                {
                    image: ( configuration.homeImage ? configuration.homeImage.image.header  : '' ),
                    amount: 0.5,
                }
            ]}
            style={{
                height: 'auto',
            }}
        >
            <div className="open-parralax">
              <div className="open-parralax-content">
                <div className="open-parralax-content-title"><h1>Matta-Carrosserie AG</h1></div>
                <TypeWriterEffect
                  textStyle={{
                    fontFamily: 'Helvetica',
                    textShadow:' 1px 1px 2px black',
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: 500,
                    marginTop: '10px',
                    fontSize: '25px',
                  }}
                  hideCursorAfterText={true}
                  startDelay={500}
                  cursorColor="white"
                  multiText={configuration.homeWriter.split(',')}
                  multiTextDelay={2500}
                  typeSpeed={30}
                />
              </div>
            </div>
            <ParallaxCache />
          </ParallaxBanner>
        <div className="page-content">
          <StartSection
            openingHours={configuration.openingHours}
            homeWelcomeText={configuration.homeWelcomeText}
            homeWelcomeTitle={configuration.homeWelcomeTitle}
          />
          <ServiceSection/>
          <PostSection/>
          <ContactSection contactText={configuration.contactText}/>
        </div>
        <Footer footerText={configuration.footerText} partnerText={configuration.partnerText} />
      </div>
    </div>
  );
}
