import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Logo from '../../assets/images/logo.svg'

const headersData = [
  {
    label: "Startseite",
    href: "/",
  },
  {
    label: "Nachrichten",
    href: "/nachrichten",
  },
  {
    label: "Dienstleistungen",
    href: "/dienstleistungen",
  },
  {
    label: "Über Uns",
    href: "/garage",
  },
];

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  header: {
    backgroundColor: "#FFFFFF",
    paddingRight: "20px",
    paddingLeft: "10px",
    "@media (max-width: 1300px)": {
      paddingLeft: '0px',
    },
  },
  logo: {
    "@media (max-width: 1300px)": {
      paddingLeft: '10px',
    },
  },
  menuButton: {
    fontFamily: "TitilliumBold !important",
    fontWeight: 900,
    size: "20px",
    marginLeft: "38px"
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px 0px 20px",
  },
}));

export default function Header(props) {
  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1300
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {logoTitle}
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
        <Toolbar>
          <IconButton
            {...{
              edge: "start",
              color: "black",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon />
        </IconButton>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <RouterLink to='/'>
            <img className="header-logo-image-menu" src={Logo} alt="Logo" />
          </RouterLink>
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <div>{logoTitle}</div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {

      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: (props.currentLocation === href) ? 'secondary' : 'inherit',
            style: { textDecoration: "none", fontFamily: "TitilliumBold", },
            key: label,
          }}
        >
          <MenuItem style={{ fontFamily: "Titillium" }}>{label}</MenuItem>
        </Link>
      );
    });
  };

  const logoTitle = (
    <Typography variant="h6" component="h1" className={logo}>
      <RouterLink to='/'>
        <img className="header-logo-image" src={Logo} alt="Logo" />
      </RouterLink>
    </Typography>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: (props.currentLocation === href) ? 'primary' : 'black',
            to: href,
            size: 'large',
            component: RouterLink,
            className: menuButton,
          }}
          style={{ fontSize: '16px', fontFamily: "TitilliumBold"}}
        >
          {label}
        </Button>
      );
    });
  };

  return (
    <header>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
