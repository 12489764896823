import React from 'react';
import SecuredZone from '../SecuredZone/SecuredZone';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';


class AdminSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingLocations: true,
      auth: {
        username: '',
        first_name: '',
        last_name: '',
        pk:'',
      },
      panels: [{
        panel: {
          id: '',
          unikey: '',
          created: '',
          spec: {},
        },
      }],
      locations:{},
    };
  }

  componentDidMount() {
      this.setState({ loading: false });
  }

  render() {
    return (
      <SecuredZone breadcrumbs={{ '/admin/': 'Dashboard','/admin/settings/': 'Einstellungen' }} title="Einstellungen" loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }>
      </SecuredZone>
    );
  }
}


export default AdminSettings;
