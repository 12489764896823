import React, { useState } from "react";
// core components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slider from '@material-ui/core/Slider';

export default function Verify(props) {

  const [images] = useState([
    require('./images/1.svg'),
    require('./images/2.svg'),
    require('./images/3.svg'),
    require('./images/4.svg'),
    require('./images/5.svg'),
    require('./images/6.svg'),
    require('./images/7.svg'),
    require('./images/8.svg'),
    require('./images/9.svg'),
    require('./images/10.svg'),
  ]);

  const [currentImage, setCurrentImage] = useState(randomImage());
  const [turn, setTurn] = useState(0);
  const [startTurn, setStartTurn] = useState(randomTurn());

  function randomTurn() {
    return (Math.random() * 250) + 60;
  }

  const changeSlider = (event, newValue) => {
      setTurn(newValue);
  };

  function randomImage() {
    var nwImg = images[Math.floor(Math.random()*images.length)].default;
    do {
       nwImg = images[Math.floor(Math.random()*images.length)].default;
    } while (nwImg === currentImage);

    return nwImg;
  }

  function check()
  {
    var result = startTurn - (-turn);
    if(result > 340 && result < 380)
    {
       props.close(true);
       setCurrentImage(randomImage());
       setStartTurn(randomTurn());
       setTurn(0);
    }
    else
    {
      setCurrentImage(randomImage());
      setStartTurn(randomTurn());
      setTurn(0);
    }
  }

  return (
    <Dialog open={props.open} onClose={props.close}>
      <div className="verify-title">Authentifizierung</div>
      <div className="verify-text">Drehen Sie das Bild in den richtigen Winkel.</div>
      <div  className="verify-image-container">
        <img alt="Verify"  className="verify-image" src={currentImage} style={{ transform: `rotate(${(turn + startTurn)}deg)` }}/>
      </div>
      <div className="verify-slider">
        <Slider value={turn} step={1} min={0} max={360} onChange={changeSlider} aria-labelledby="continuous-slider" />
      </div>
      <div className="verify-button" >
        <Button variant="outlined" onClick={() => props.close(false)}>Abbrechen</Button>
        <span></span>
        <Button variant="outlined" onClick={() => check()}>Weiter</Button>
      </div>
    </Dialog>
  );
}
