import Logo from '../../assets/images/logo.svg';
import { Link  } from "react-router-dom";
import React from 'react';
import LockIcon from '@material-ui/icons/Lock';
import IconButton from '@material-ui/core/IconButton';

const TopBar = () => {

  return (
    <div className="top-bar">
      <div className="top-bar-logo-container">
        <Link to="/">
          <img className="top-bar-logo" src={Logo} alt="Logo" />;
        </Link>
      </div>
      <span></span>
      <div className="top-bar-logout">
        <Link to="/auth/logout/">
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            to="/auth/logout">
            <LockIcon />
          </IconButton>
        </Link>
      </div>
    </div>
  );
};

export default TopBar;
