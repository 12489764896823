export default function useAuth() {
  var token = '';
  var isAuth = false;
  var favicon = document.getElementById("favicon");

  if (localStorage.getItem('token') !== null)
  {
    isAuth = true;
    token = localStorage.getItem('token');
    favicon.href = "/favicon.admin.svg";

  }
  else {
    favicon.href = "/favicon.svg";
  }

  return {
    token: token,
    isAuth: isAuth,
  }
}
