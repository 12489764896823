import React from "react";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import { Link as RouterLink } from "react-router-dom";
// nodejs library to set properties for components

export default function ServiceArea(props) {
  const { title, description, image, linkKey } = props;

  return (
    <RouterLink key={linkKey} className="info-area-link" to={`/dienstleistungen/${title.toString().replace(/\W/g, '')}.html#${(linkKey?.split('-')[0])}`}>
      <div className="info-area">
        <div className="info-area-icon-wrapper">
          { (typeof(image) === 'object') ?
            image
            :
            <>
              <img className="info-area-icon" src={image} alt={title}/>
              <div className="info-area-icon-cover" style={{ backgroundImage: `url(${image})`, }}></div>
            </>
          }
        </div>
        <div className="info-area-description-wrapper">
          <h4 className="info-area-title">{title}</h4>
          <p className="info-area-description">{description}</p>
        </div>
      </div>
    </RouterLink>
  );
}

export function ServiceExtendedArea(props) {
  const { title, text, image, index, unikey } = props;

  return (
    <div id={unikey?.split('-')[0]} key={unikey} className={ (index % 2) ? "post-extended-area turn-post" : "post-extended-area"}>
      <GridContainer justify="center">
        { !(index % 2) ?

          <GridItem xs={12} sm={6} md={3}>
            <div className="service-extended-area-image-wrapper">
              { (typeof(image) === 'object') ?
                image
                :
                <>
                  <img className="service-extended-area-image" src={image} alt={title}/>
                  <div className="service-extended-area-image-cover" style={{ backgroundImage: `url(${image})`, }}></div>
                </>
              }
            </div>
          </GridItem>
          :
          ''
        }
        <GridItem xs={12} sm={6} md={5}>
          <div className="service-area-description-wrapper">
            <h4 className="service-extended-area-title">{title}</h4>
            <div className="service-area-description" dangerouslySetInnerHTML={{__html: text}}/>
          </div>
        </GridItem>
        { (index % 2) ?

          <GridItem xs={12} sm={6} md={4}>
            <div className="service-extended-area-image-wrapper">
              <img className="service-extended-area-image" src={image} alt={title}/>
              <div className="service-extended-area-image-cover" style={{ backgroundImage: `url(${image})`, }}></div>
            </div>
          </GridItem>
          :
          ''
        }
      </GridContainer>
    </div>
  );
}
