import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import WebIcon from '@material-ui/icons/Web';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link ,useLocation } from "react-router-dom";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ListAltIcon from '@material-ui/icons/ListAlt';
import '../../assets/css/admin.css';

export default function SideBar(props) {

  const location = useLocation()
  const useStyles = makeStyles({
    listItemText:{
      fontFamily:"Titillium",
    }
  });

  const classes = useStyles();

  return (
    <List>
      <ListItem button style={{ backgroundColor:((location.pathname === "/admin/") ? '#4e5359' : 'inherit' ) }} component={Link} to="/admin/" key="Dashboard">
        <ListItemIcon ><DashboardIcon /></ListItemIcon>
        <ListItemText classes={{primary:classes.listItemText}} primary="Dashboard" />
      </ListItem>
      <ListItem button style={{ backgroundColor:((location.pathname === "/admin/site") ? '#4e5359' : 'inherit' ) }} component={Link} to="/admin/site" key="Allgemein">
        <ListItemIcon><WebIcon /></ListItemIcon>
        <ListItemText classes={{primary:classes.listItemText}} primary="Allgemein" />
      </ListItem>
      <ListItem button style={{ backgroundColor:((location.pathname === "/admin/posts") ? '#4e5359' : 'inherit' ) }} component={Link} to="/admin/posts" key="Posts">
        <ListItemIcon><MailOutlineIcon /></ListItemIcon>
        <ListItemText classes={{primary:classes.listItemText}} primary="Posts" />
      </ListItem>
      <ListItem button style={{ backgroundColor:((location.pathname === "/admin/services") ? '#4e5359' : 'inherit' ) }} component={Link} to="/admin/services" key="Dienstleistungen">
        <ListItemIcon><AttachFileIcon /></ListItemIcon>
        <ListItemText classes={{primary:classes.listItemText}} primary="Dienstleistungen" />
      </ListItem>
      <ListItem button style={{ backgroundColor:((location.pathname === "/admin/employees") ? '#4e5359' : 'inherit' ) }} component={Link} to="/admin/employees" key="Mitarbeiter">
        <ListItemIcon><PersonOutlineIcon /></ListItemIcon>
        <ListItemText classes={{primary:classes.listItemText}} primary="Mitarbeiter" />
      </ListItem>
      <ListItem button style={{ backgroundColor:((location.pathname === "/admin/appointment") ? '#4e5359' : 'inherit' ) }} component={Link} to="/admin/appointment" key="OnlineTermin">
        <ListItemIcon><CalendarTodayIcon /></ListItemIcon>
        <ListItemText classes={{primary:classes.listItemText}} primary="Online Termin" />
      </ListItem>
      <ListItem button style={{ backgroundColor:((location.pathname === "/admin/forms") ? '#4e5359' : 'inherit' ) }} component={Link} to="/admin/forms" key="Formulare">
        <ListItemIcon><ListAltIcon /></ListItemIcon>
        <ListItemText classes={{primary:classes.listItemText}} primary="Formulare" />
      </ListItem>
      <ListItem button style={{ backgroundColor:((location.pathname === "/admin/settings") ? '#4e5359' : 'inherit' ) }} component={Link} to="/admin/settings" key="Einstellungen">
        <ListItemIcon><SettingsIcon /></ListItemIcon>
        <ListItemText classes={{primary:classes.listItemText}} primary="Einstellungen" />
      </ListItem>
    </List>
  );
}
