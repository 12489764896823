import React from "react";
// @material-ui/icons
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";

export default function OnlineTerminList(props)
{
  const { image, title, description, checkbox } = props;

  return (
    <div className="online-list-area">
      <GridContainer alignItems="center" justify="center" style={{ width:'100%' }}>
        <GridItem xs={12} sm={4} md={2}>
          <div className="online-list-area-image-wrapper">
            { (typeof(image) === 'object') ?
              image
              :
              <>
                <img className="online-list-area-image" src={image} alt={title}/>
              </>
            }
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={1}>
          <div className="online-list-area-checkbox">
            {checkbox}
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <div className="online-list-area-description-wrapper">
            <h4 className="online-list-area-name">{title}</h4>
            <p className="online-list-area-description">{description}</p>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
