import React from "react";
// @material-ui/icons
import GridContainer from "../Grid/GridContainer.js";
import IconButton from '@material-ui/core/IconButton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';

export default function EmployeesArea(props)
{
  const { image, name, position, job, email, phone, description } = props;

  return (
    <div className="employees-area">
      <div className="employees-area-image-wrapper">
        { (typeof(image) === 'object') ?
          image
          :
          <>
            <img className="employees-area-image" src={image} alt={name}/>
            <div className="employees-area-image-cover" style={{ backgroundImage: `url(${image})`, }}></div>
          </>
        }
      </div>
      <div className="employees-area-description-wrapper">
        <h4 className="employees-area-name">{name}</h4>
        <p className="employees-area-position">{position}</p>
        <p className="employees-area-job">{job}</p>
        <p className="employees-area-description">{description}</p>
      </div>
      <GridContainer style={{ gridGap: '20px' }} justify="center">
        <IconButton aria-label={email} onClick={() => window.open('mailto:'+email)} size="small">
            <MailOutlineIcon fontSize="inherit" />
        </IconButton>
        <IconButton aria-label={phone} onClick={() => window.open('tel:'+phone)} size="small">
            <PhoneIcon fontSize="inherit" />
        </IconButton>
      </GridContainer>
    </div>
  );
}
