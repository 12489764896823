import React, { useState, useEffect } from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import ServiceArea from "../../../components/ServiceArea/ServiceArea.js";
import Skeleton from '@material-ui/lab/Skeleton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//api
import API from '../../../api/api';

const garageApi = new API();

export default function ServiceSection(props) {

  const [services, setServices] = useState({});
  const [snackBar, setSnackBar] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);

  useEffect(() => {

    garageApi.openEndpoints.services.getAll()
      .then((servicesGET) => {
        try
        {
          setServices(servicesGET.data.results);
          setLoadingServices(true);
        }
        catch(e)
        {
          setSnackBar(true);
        }
      });

  }, []);

  function closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  }

  return (
    <>
      <div className="grid-section">
        <Snackbar open={snackBar} autoHideDuration={6000} onClose={closeSnackBar}>
          <MuiAlert elevation={6} variant="filled" onClose={closeSnackBar} severity="warning" >Server Fehler 500</MuiAlert>
        </Snackbar>
        <h2 style={{ marginBottom: '60px' }} className="grid-title">Dienstleistungen</h2>
        <GridContainer>

          { loadingServices ?
            <>
              { services.map(function(service){
                return (
                  <GridItem key={service.unikey} xs={12} sm={4} md={4}>
                    <ServiceArea
                      key={service.unikey}
                      linkKey={service.unikey}
                      title={service.title}
                      description={service.description}
                      image={service.image.image['400']}
                    />
                  </GridItem>
                )
               })}
             </>
            :
            Array.from({ length: 3 }, (_, i) =>
              <GridItem key={i} xs={12} sm={12} md={4}>
                <ServiceArea
                  title={<Skeleton variant="text" width={'100%'} />}
                  description=<Skeleton variant="rect" width={'100%'} height={118} />
                  image=<Skeleton variant="circle" width={100} height={100} />
                />
              </GridItem>
            )
          }
        </GridContainer>
      </div>

    </>
  );
}
