import React from 'react';
import SecuredZone from '../SecuredZone/SecuredZone';
import Dialog from '@material-ui/core/Dialog'
import API from '../../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import AdminAppointmentServicesDialog from '../../../dialogs/admin-appointment-services/admin-appointment-services';
import AdminAppointmentChecksDialog from '../../../dialogs/admin-appointment-checks/admin-appointment-checks';

const garageApi = new API();

/*{moment(row.created).format('DD.MM.YYYY HH:mm')}*/

class AdminAppointment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingServices: true,
      adminAppointmentServicesDialog: false,
      appointmentServices: [],
      editAppointmentService: {},

      loadingChecks: true,
      adminAppointmentChecksDialog: false,
      appointmentChecks: [],
      editAppointmentCheck: {},
    };
  }

  componentDidMount()
  {
    this.loadAppointmentServices();
    this.loadAppointmentChecks();
  }

  loadAppointmentServices()
  {
    garageApi.endpoints.appointmentServices.getAll()
      .then((getRequest) => {
        try
        {
          this.setState({ appointmentServices:  getRequest.data.results });
          this.setState({ loadingServices: false });
        }
        catch(e)
        {
          console.log(e);
        }
      });
  }

  loadAppointmentChecks()
  {
    garageApi.endpoints.appointmentChecks.getAll()
      .then((getRequest) => {
        try
        {
          this.setState({ appointmentChecks:  getRequest.data.results });
          this.setState({ loadingChecks: false });
        }
        catch(e)
        {
          console.log(e);
        }
      });
  }

  deleteAppointmentService(unikey)
  {
    var deleteAppointmentService = this.state.appointmentServices.find((element) => { return element.unikey === unikey; })

    this.setState({ loadingServices: true });

    garageApi.endpoints.appointmentServices.delete( {id: deleteAppointmentService.id })
      .then((getRequest) => {
        try
        {
          this.loadAppointmentServices();
        }
        catch(e)
        {
          console.log(e);
        }
      });
  }

  deleteAppointmentCheck(unikey)
  {
    var deleteAppointmentCheck = this.state.appointmentChecls.find((element) => { return element.unikey === unikey; })

    this.setState({ loadingChecks: true });

    garageApi.endpoints.appointmentServices.delete( {id: deleteAppointmentCheck.id })
      .then((getRequest) => {
        try
        {
          this.loadAppointmentChecks();
        }
        catch(e)
        {
          console.log(e);
        }
      });
  }

  closeAdminAppointmentServicesDialog()
  {
    this.setState({ adminAppointmentServicesDialog: false });
    this.setState({ editAppointmentService: {} });
  }

  closeAdminAppointmentChecksDialog()
  {
    this.setState({ adminAppointmentChecksDialog: false });
    this.setState({ editAppointmentCheck: {} });
  }

  submitAdminAppointmentServicesDialog(mode,service)
  {
    this.setState({ loadingServices: true });
    if(mode)
    {
      garageApi.endpoints.appointmentServices.create(service)
        .then((getRequest) => {
          try
          {
            this.loadAppointmentServices();
          }
          catch(e)
          {
            console.log(e);
          }
        });
    }
    else
    {
      garageApi.endpoints.appointmentServices.update(service)
        .then((getRequest) => {
          try
          {
            this.loadAppointmentServices();
          }
          catch(e)
          {
            console.log(e);
          }
        });
    }
    this.setState({ adminAppointmentServicesDialog: false });
    this.setState({ editAppointmentService: {} });
  }

  submitAdminAppointmentChecksDialog(mode,check)
  {
    this.setState({ loadingChecks: true });
    if(mode)
    {
      garageApi.endpoints.appointmentChecks.create(check)
        .then((getRequest) => {
          try
          {
            this.loadAppointmentChecks();
          }
          catch(e)
          {
            console.log(e);
          }
        });
    }
    else
    {
      garageApi.endpoints.appointmentChecks.update(check)
        .then((getRequest) => {
          try
          {
            this.loadAppointmentChecks();
          }
          catch(e)
          {
            console.log(e);
          }
        });
    }
    this.setState({ adminAppointmentChecksDialog: false });
    this.setState({ editAppointmentCheck: {} });
  }

  editAppointmentCheck(unikey)
  {
    var editAppointmentCheck = this.state.appointmentChecks.find((element) => { return element.unikey === unikey; })
    this.setState({ editAppointmentCheck: editAppointmentCheck });
    this.setState({ adminAppointmentChecksDialog: true });
  }

  editAppointmentService(unikey)
  {
    var editAppointmentService = this.state.appointmentServices.find((element) => { return element.unikey === unikey; })
    this.setState({ editAppointmentService: editAppointmentService });
    this.setState({ adminAppointmentServicesDialog: true });
  }

  createCheck()
  {
    this.setState({ adminAppointmentChecksDialog: true });
  }

  createService()
  {
    this.setState({ adminAppointmentServicesDialog: true });
  }

  render() {
    return (
      <SecuredZone
        breadcrumbs={{ '/admin/': 'Dashboard','/admin/appointment': 'Online Termin' }}
        menu={{ 'Dienstleistung Hinzufügen': this.createService.bind(this), 'Check Hinzufügen': this.createCheck.bind(this)}}
        title="Online Termin"
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
      >
        <Dialog open={this.state.adminAppointmentServicesDialog} onClose={this.closeAdminAppointmentServicesDialog.bind(this)}>
          <AdminAppointmentServicesDialog appointmentServicesData={this.state.editAppointmentService} close={this.closeAdminAppointmentServicesDialog.bind(this)} submit={this.submitAdminAppointmentServicesDialog.bind(this)}/>
        </Dialog>
        <Dialog open={this.state.adminAppointmentChecksDialog} onClose={this.closeAdminAppointmentChecksDialog.bind(this)}>
          <AdminAppointmentChecksDialog appointmentChecksData={this.state.editAppointmentCheck} close={this.closeAdminAppointmentChecksDialog.bind(this)} submit={this.submitAdminAppointmentChecksDialog.bind(this)}/>
        </Dialog>
        { this.state.loadingServices ?
          <span></span>
          :
          <>
            <div className="settings-title">Dienstleistungen</div>
            <TableContainer className="settings-table" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Titel</TableCell>
                    <TableCell>Beschreibung</TableCell>
                    <TableCell width={22} align="left"></TableCell>
                    <TableCell width={22} align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { Array.isArray(this.state.appointmentServices) ?
                    <>
                      {this.state.appointmentServices.map((row) => (
                        <TableRow key={row.unikey}>
                          <TableCell component="th" scope="row">{row.title}</TableCell>
                          <TableCell component="th" scope="row">{row.description}</TableCell>
                          <TableCell size="small">
                            <div className="table-buttons">
                              <IconButton onClick={() => {this.editAppointmentService(row.unikey)}} size="small">
                                  <EditIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </TableCell>
                          <TableCell size="small">
                            <div className="table-buttons">
                              <IconButton onClick={() => {this.deleteAppointmentService(row.unikey)}} size="small">
                                  <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                    :
                    <>
                    </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
        }
        { this.state.loadingChecks ?
          <span></span>
          :
          <div style={{ marginTop: '60px' }}>
            <div className="settings-title">Checks</div>
            <TableContainer className="settings-table" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Titel</TableCell>
                    <TableCell>Beschreibung</TableCell>
                    <TableCell width={22} align="left"></TableCell>
                    <TableCell width={22} align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { Array.isArray(this.state.appointmentChecks) ?
                    <>
                      {this.state.appointmentChecks.map((row) => (
                        <TableRow key={row.unikey}>
                          <TableCell component="th" scope="row">{row.title}</TableCell>
                          <TableCell component="th" scope="row">{row.description}</TableCell>
                          <TableCell size="small">
                            <div className="table-buttons">
                              <IconButton onClick={() => {this.editAppointmentCheck(row.unikey)}} size="small">
                                  <EditIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </TableCell>
                          <TableCell size="small">
                            <div className="table-buttons">
                              <IconButton onClick={() => {this.deleteAppointmentCheck(row.unikey)}} size="small">
                                  <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                    :
                    <>
                    </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        }
      </SecuredZone>
    );
  }
}


export default AdminAppointment;
