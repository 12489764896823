import React, { useState, useEffect } from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import { PostExtendedArea } from "../../../components/PostArea/PostArea.js";
import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '@material-ui/lab/Pagination';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//api
import API from '../../../api/api';

const garageApi = new API();

export default function PostSection() {

  const [posts, setPosts] = useState({});
  const [snackBar, setSnackBar] = useState(false);
  const [postsCount, setPostsCount] = useState({});
  const [loadingPosts, setLoadingPosts] = useState(false);

  useEffect(() => {
    loadPage(0);
  }, []);

  function closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  }

  function loadPage(value) {
    garageApi.openEndpoints.posts.getPage( { get: { limit:10, offset:(((value -1) * 10)) }  } )
      .then((postsGET) => {
        try
        {
          setPosts(postsGET.data.results);
          setPostsCount(postsGET.data.count)
          setLoadingPosts(true);
        }
        catch(e)
        {
          setSnackBar(true);
        }

      });
  }

  const changePage = (event, value) => {
    loadPage(value);
  };

  return (
    <>
      <Snackbar open={snackBar} autoHideDuration={6000} onClose={closeSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={closeSnackBar} severity="warning" >Server Fehler 500</MuiAlert>
      </Snackbar>
      <div className="grid-section">

        <div className="mouse-scroll">
          <div className="mouse">
            <div className="mouse-in"></div>
          </div>
          <div>
              <span className="down-arrow-1"></span>
              <span className="down-arrow-2"></span>
              <span className="down-arrow-3"></span>
           </div>
        </div>
        <GridContainer style={{ marginTop: '60px', marginBottom: '20px'}}>

          { loadingPosts ?
            <>
              { posts.map(function(post, index){
                return (
                  <GridItem xs={12} sm={12} md={12}>
                    <PostExtendedArea
                      index={index}
                      title={post.title}
                      description={post.description}
                      image={post.image.image['post']}
                      unikey={post.unikey}
                    />
                  </GridItem>
                )
               })}
             </>
            :
            Array.from({ length: 3 }, (_, i) =>
              <GridItem xs={12} sm={6} md={4}>
                <PostExtendedArea
                  title={<Skeleton variant="text" width={'100%'} />}
                  description=<Skeleton variant="rect" width={'100%'} height={118} />
                  image= <Skeleton variant="rect" width={'100%'} height={200} />
                  key=''
                />
              </GridItem>
            )
          }
          </GridContainer>
          <GridContainer justify="center">
          { postsCount > 10 ?
              <Pagination onChange={changePage} count={(Math.ceil(postsCount/10))} />
            :
            null
          }
        </GridContainer>
      </div>
    </>
  );
}
