import React from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button'
import { Link as RouterLink } from "react-router-dom";

export default function PostSection(props) {

  return (
    <>
      <div className="grid-section">

        <div className="mouse-scroll">
          <div className="mouse">
            <div className="mouse-in"></div>
          </div>
          <div>
              <span className="down-arrow-1"></span>
              <span className="down-arrow-2"></span>
              <span className="down-arrow-3"></span>
           </div>
        </div>
        <GridContainer justify="center">

          { props.loadingPost ?

              <GridItem xs={12} sm={10} md={8}>
                <div style={{ marginTop: '60px', marginBottom: '50px' }} className="posts-html" dangerouslySetInnerHTML={{__html: props.data.text}}/>
              </GridItem>
            :
            <>
              <GridItem xs={12} sm={10} md={8}>
                <div className="post-area">
                  <div className="post-area-image-wrapper">
                    <Skeleton variant="rect" style={{ marginTop: '50px' }} width={'100%'}  height={'100px'} />
                  </div>
                  <div className="post-area-description-wrapper">
                    <h4 className="post-area-title"><Skeleton variant="rect" width={'100%'}  height={'600px'}/></h4>
                  </div>
                </div>
              </GridItem>
            </>
          }
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Button component={RouterLink} to={'/nachrichten/'} style={{ fontWeight:'bold', color:'#14a0db' }}>Zurück zu den Nachrichten</Button>
          </GridItem>
        </GridContainer>
      </div>
    </>
  );
}
