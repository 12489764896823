import React from "react";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Logo from '../../assets/images/logo.svg'
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from "react-router-dom";
import facebook from '../../assets/images/facebook.png';
import twitter from '../../assets/images/twitter.png';
import instagram from '../../assets/images/instagram.png';


export default function HeaderLinks(props) {

  return (
    <div className="footer">
      <div style={{ padding: '0px', textAlign: 'left' }} className="grid-section">
        <GridContainer justify="center" style={{ maxWidth: '100%'}}>
          <GridItem xs={12} sm={6} md={3}>
            <div className="footer-title">
              <img className="footer-logo" src={Logo} alt="Logo" />
            </div>
            <div className="footer-links">
              <div className="footer-box">
                <Link  component={RouterLink} to='/impressum' style={{ color:'#14a0db' }}>Impressum</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/datenschutz' style={{ color:'#14a0db' }}>Datenschutz</Link>
              </div>
              <div className="footer-box">
                <a rel="noreferrer" target='_blank' href="https://www.facebook.com/mattacarrosserie/" style={{ color:'#14a0db' }}>
                  <div className="footer-box-external-link">
                    <img className="footer-box-external-link-image" alt='Facebook' src={facebook}></img>
                    <div className="footer-box-external-link-text">Facebook</div>
                  </div>
                </a>
              </div>
              <div className="footer-box">
                <a rel="noreferrer" target='_blank' href="https://www.instagram.com/mattacarrosserie/" style={{ color:'#14a0db' }}>
                  <div className="footer-box-external-link">
                    <img className="footer-box-external-link-image" alt='Instagram' src={instagram}></img>
                    <div className="footer-box-external-link-text">Instagram</div>
                  </div>
                </a>
              </div>
              <div className="footer-box">
                <a rel="noreferrer" target='_blank' href="https://twitter.com/mattacarrosserie" style={{ color:'#14a0db' }}>
                  <div className="footer-box-external-link">
                    <img className="footer-box-external-link-image" alt='Twitter' src={twitter}></img>
                    <div className="footer-box-external-link-text">Twitter</div>
                  </div>
                </a>
              </div>
            </div>

          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <h5 className="footer-title">Navigation</h5>
            <div className="footer-links">
              <div className="footer-box">
                <Link  component={RouterLink} to='/' style={{ color:'#14a0db' }}>Startseite</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/nachrichten' style={{ color:'#14a0db' }}>Nachrichten</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/dienstleistungen' style={{ color:'#14a0db' }}>Dienstleistungen</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/garage' style={{ color:'#14a0db' }}>Garage</Link>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <h5 className="footer-title">Kontakt</h5>
            <div className="footer-box" dangerouslySetInnerHTML={{__html: props.footerText}}></div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <h5 className="footer-title">Partner</h5>
            <div className="footer-box" dangerouslySetInnerHTML={{__html: props.partnerText}} ></div>
          </GridItem>
        </GridContainer>
      </div>
      <div className="copyright-header">Matta-Carrosserie © {(new Date().getFullYear())}</div>
    </div>
  );
}
