import React, { useState, useEffect } from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import PostArea from "../../../components/PostArea/PostArea.js";
import Skeleton from '@material-ui/lab/Skeleton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//api
import API from '../../../api/api';

const garageApi = new API();

export default function ServiceSection() {

  const [posts, setPosts] = useState({});
  const [snackBar, setSnackBar] = useState(false);
  const [loadingPosts, setLoadingPosts] = useState(false);

  useEffect(() => {

    garageApi.openEndpoints.posts.getPage( { get: { limit:3, offset:0 }  } )
      .then((postsGET) => {
        try
        {
          setPosts(postsGET.data.results);
          setLoadingPosts(true);
        }
        catch(e)
        {
          setSnackBar(true);
        }

      });

  }, []);

  function closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  }

  return (
    <>
      <div className="grid-section">
        <Snackbar open={snackBar} autoHideDuration={6000} onClose={closeSnackBar}>
          <MuiAlert elevation={6} variant="filled" onClose={closeSnackBar} severity="warning" >Server Fehler 500</MuiAlert>
        </Snackbar>
        <h2  style={{ marginBottom: '50px'}} className="grid-title">Mitteilungen</h2>
        <GridContainer>

          { loadingPosts ?
            <>
              { posts.map(function(post){
                return (
                  <GridItem xs={12} sm={6} md={4}>
                    <PostArea
                      title={post.title}
                      description={post.description}
                      image={post.image.image['post']}
                      unikey={post.unikey}
                    />
                  </GridItem>
                )
               })}
             </>
            :
            Array.from({ length: 3 }, (_, i) =>
              <GridItem xs={12} sm={6} md={4}>
                <PostArea
                  title={<Skeleton variant="text" width={'100%'} />}
                  description=<Skeleton variant="rect" width={'100%'} height={118} />
                  image=<Skeleton variant="rect" width={'100%'} height={100} />
                  key=''
                />
              </GridItem>
            )
          }
        </GridContainer>
      </div>
    </>
  );
}
