import React, { useState } from "react";
// @material-ui/core components
import TextField from '@material-ui/core/TextField';
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Verify from "../../../components/Verify/Verify.js";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//api
import API from '../../../api/api';

const garageApi = new API();

export default function ContactSection(props) {

  const [verify, setVerify] = useState(false);
  const [sent, setSent] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const [contact, setContact] = useState({
    name: '',
    email: '',
    message:''
  });

  const [contactError, setContactError] = useState({
    name: false,
    email: false,
    message:false
  });

  function verifyDialog()
  {
    var update = {...contactError};
    if(contact.name === "") {
      update.name = true;
    }
    else if (contact.email === "") {
      update.email = true;
    }
    else if (contact.message === "") {
      update.message = true;
    }
    else
    {
      update.email = false;
      update.name = false;
      update.message = false;
      setVerify(true);
    }
    setContactError(update);
  }

  function closeVerifyDialog(result)
  {
    if(result)
    {
      garageApi.openEndpoints.formContact.create(contact)
        .then((resultGET) => {
          try
          {
            contact.name = "";
            contact.email = "";
            contact.message = "";
            setSent(true)
          }
          catch(e)
          {
            setSnackBar(true);
          }
        });
    }
    setVerify(false);
  }

  function changeContact(e,key)
  {
    var update = {...contact};
    update[key] = e.target.value;
    setContact(update);
  }

  function closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  }

  return (
    <div className='grid-section'>
      <Snackbar open={snackBar} autoHideDuration={6000} onClose={closeSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={closeSnackBar} severity="warning" >Server Fehler 500</MuiAlert>
      </Snackbar>
      <Verify open={verify} close={closeVerifyDialog}/>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className='grid-title'>Kontakt</h2>
          <h4 className='grid-description' dangerouslySetInnerHTML={{__html: props.contactText}}></h4>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  label="Name"
                  fullWidth
                  error={contactError.name}
                  style={{
                    marginBottom: '20px',
                  }}
                  value={contact.name}
                  onChange={e => changeContact(e,'name')}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  label="E-Mail Adresse"
                  fullWidth
                  error={contactError.email}
                  type="email"
                  style={{
                    marginBottom: '20px',
                  }}
                  value={contact.email}
                  onChange={e => changeContact(e,'email')}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  label="Nachricht"
                  multiline
                  error={contactError.message}
                  fullWidth
                  minRows={4}
                  style={{
                    marginTop:'10px',
                    marginBottom: '40px',
                  }}
                  value={contact.message}
                  onChange={e => changeContact(e,'message')}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {sent ?
                  <p>Ihre Kontakt Anfrage wurde versandt.</p>
                  :
                  <Button variant="contained" onClick={() => verifyDialog()} color="primary">Senden</Button>
                }
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
