import React from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';

export function CollapseAppointment(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.firstname} {row.secondname}</TableCell>
        <TableCell align="left">{row.carMake} {row.carModel}</TableCell>
        <TableCell align="left">{row.emailAddress}</TableCell>
        <TableCell align="left">{row.phoneNumber}</TableCell>
        <TableCell>{moment(row.created).format('DD.MM.YYYY HH:mm')}</TableCell>
      </TableRow>
      <TableRow className={ open ? "" : "collapse-row" }>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse style={{ marginBottom: '50px' }} in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="data">
                <TableBody>
                  <TableRow>
                    <TableCell>Vorname</TableCell>
                    <TableCell>{row.firstname}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Nachname</TableCell>
                    <TableCell>{row.secondname}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Auto Marke</TableCell>
                    <TableCell>{row.carMake}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Auto Modell</TableCell>
                    <TableCell>{row.carModel}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>E-Mail Adresse</TableCell>
                    <TableCell>{row.emailAddress}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Telefon Nummer</TableCell>
                    <TableCell>{row.phoneNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Checks</TableCell>
                    <TableCell>
                    {row.checks.map((checksRow) => (
                      <p key={checksRow.unikey}>{checksRow.title}</p>
                    ))}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Dienstleistungen</TableCell>
                    <TableCell>
                    {row.services.map((servicesRow) => (
                      <p key={servicesRow.unikey}>{servicesRow.title}</p>
                    ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Gewünschtes Datum</TableCell>
                    <TableCell style={{ color:'orange', fontWeight: 'bold' }}>{moment(row.dateTime).format('DD.MM.YYYY HH:mm')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Erstellt</TableCell>
                    <TableCell>{moment(row.created).format('DD.MM.YYYY HH:mm')}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function CollapseContact(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.name}</TableCell>
        <TableCell align="left">{row.email}</TableCell>
        <TableCell>{moment(row.created).format('DD.MM.YYYY HH:mm')}</TableCell>
      </TableRow>
      <TableRow className={ open ? "" : "collapse-row" }>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse style={{ marginBottom: '50px' }} in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="data">
                <TableBody>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>{row.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>E-Mail Adresse</TableCell>
                    <TableCell>{row.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Nachricht</TableCell>
                    <TableCell>{row.message}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Erstellt</TableCell>
                    <TableCell>{moment(row.created).format('DD.MM.YYYY HH:mm')}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
