import React, { useState, useEffect } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer"
import ParallaxCache from "../../components/Parallax/ParallaxCache";
import { ParallaxBanner  } from 'react-scroll-parallax';
import { useLocation, useParams } from 'react-router-dom'
import PostSection from "./Sections/PostSection";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PopOutInfo from "../../components/PopOutInfo/PopOutInfo";
import {Helmet} from "react-helmet";
//api
import API from '../../api/api';

const garageApi = new API();

export default function NewsOnePage(props) {
  const location = useLocation();
  const { configuration } = props;
  const { id } = useParams();
  const [snackBar, setSnackBar] = useState(false);
  const [post, setPost] = useState({});
  const [loadingPost, setLoadingPost] = useState(false);

  useEffect(() => {

    garageApi.openEndpoints.posts.getOne( { id: id } )
      .then((postGET) => {
        try
        {
          setPost(postGET.data[0]);
          setLoadingPost(true);
        }
        catch(e)
        {
          setSnackBar(true);
        }

      });
   // eslint-disable-next-line
  }, []);

  function closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  }


  return (
    <div>
    <Helmet>
        <title>Matta-Carrosserie - { loadingPost ? post.title : 'Nachrichten'}</title>
    </Helmet>
      <PopOutInfo
        openingHours={configuration.openingHours}
        mapsLink={configuration.mapsLink}
        mapsLinkText={configuration.mapsLinkText}
        popUpcontactText={configuration.popUpcontactText}
      />
      <Snackbar open={snackBar} autoHideDuration={6000} onClose={closeSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={closeSnackBar} severity="warning" >Server Fehler 500</MuiAlert>
      </Snackbar>
      <Header
        color="transparent"
        fixed
        currentLocation={location.pathname}
      />
      <div className="page">
        <ParallaxBanner
            className="open-parralax-container"
            layers={[
                {
                    image: ( loadingPost ? post.image.image.header : ''),
                    amount: 0.5,
                }
            ]}
            style={{
                height: '100%',
            }}
        >
            <div className="open-parralax">
              <div className="open-parralax-content">
                <div className="open-parralax-content-title">{ loadingPost ? post.title : 'Nachrichten'}</div>
              </div>
            </div>
            <ParallaxCache />
        </ParallaxBanner>
        <div className="page-content">
          <PostSection data={post} loadingPost={loadingPost}  />
        </div>
        <Footer footerText={configuration.footerText} partnerText={configuration.partnerText} />
      </div>
    </div>
  );
}
