import React, { useState, useEffect } from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import EmployeesArea from "../../../components/EmployeesArea/EmployeesArea";
import Skeleton from '@material-ui/lab/Skeleton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//api
import API from '../../../api/api';

const garageApi = new API();

export default function EmployeesSection(props) {

  const [employees, setEmployees] = useState({});
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [snackBar, setSnackBar] = useState(false);

  useEffect(() => {

    garageApi.openEndpoints.employees.getAll()
      .then((employeesGET) => {
        try
        {
          setEmployees(employeesGET.data.results);
          setLoadingEmployees(true);
        }
        catch(e)
        {
          setSnackBar(true);
        }

      });

  }, []);

  function closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  }


  return (
    <>
      <Snackbar open={snackBar} autoHideDuration={6000} onClose={closeSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={closeSnackBar} severity="warning" >Server Fehler 500</MuiAlert>
      </Snackbar>
      <div className="grid-section">

        <div className="mouse-scroll">
          <div className="mouse">
            <div className="mouse-in"></div>
          </div>
          <div>
              <span className="down-arrow-1"></span>
              <span className="down-arrow-2"></span>
              <span className="down-arrow-3"></span>
           </div>
        </div>

        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={6}>
            <h2 className="grid-title">Über Uns</h2>
            <h5 className="grid-description" dangerouslySetInnerHTML={{__html: props.garageAboutText}}></h5>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <h2 className="grid-title">Mitarbeiter</h2>
          </GridItem>


          { loadingEmployees ?
            <>
              { employees.map(function(employee, index){
                return (
                  <GridItem key={index} xs={12} sm={6} md={4}>
                    <EmployeesArea
                      index={index}
                      image={employee.image.image['400']}
                      name={employee.name}
                      position={employee.position}
                      job={employee.job}
                      email={employee.email}
                      phone={employee.phone}
                      description={employee.description}
                      unikey={employee.unikey}
                    />
                  </GridItem>
                )
               })}
             </>
            :
            Array.from({ length: 3 }, (_, i) =>
              <GridItem xs={12} sm={6} md={4}>
                <EmployeesArea
                  name={<Skeleton variant="text" width={'100%'} />}
                  position={<Skeleton variant="text" width={'100%'} />}
                  job={<Skeleton variant="text" width={'100%'} />}
                  email={<Skeleton variant="text" width={'100%'} />}
                  phone={<Skeleton variant="text" width={'100%'} />}
                  description={<Skeleton variant="text" width={'100%'} />}
                  image=<Skeleton variant="circle" width={150} height={150}/>
                  unikey=''
                  key={i}
                />
              </GridItem>
            )
          }
        </GridContainer>
      </div>
    </>
  );
}
