import React from "react";
// @material-ui/icons
import Button from '@material-ui/core/Button';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GoogleMaps from '../../assets/images/google_maps.svg'
import Icon from '@material-ui/core/Icon';


function valProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function convertToTime(number) {
    // Check sign of given number
    var sign = (number >= 0) ? 1 : -1;
    // Set positive value of number of sign negative
    number = number * sign;
    // Separate the int from the decimal part
    var hour = Math.floor(number);
    var decpart = number - hour;
    var min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);
    var minute = Math.floor(decpart * 60) + '';
    // Add padding if need
    if (minute.length < 2) {
    minute = '0' + minute;
    }
    // Add Sign in final result
    sign = sign === 1 ? '' : '-';
    // Concate hours and minutes
    var time = sign + hour + ':' + minute;

    return time;
}

export default function PopOutInfo(props) {
  const { openingHours, mapsLink, mapsLinkText, popUpcontactText } = props;

  const [value, setValue] = React.useState(0);
  const [pxOut, setPxOut] = React.useState(-250);

  const handleChange = (event, newValue) => {
    if(value === newValue && pxOut !== -250)
    {
      setPxOut(-250);
    }
    else
    {
      setPxOut(0);
      setValue(newValue);
    }

  };

  var sortedOpeningHours = [];
  var printOpeningHoursArray = [];

  const sorter = {
    "mon": 1,
    "tue": 2,
    "wed": 3,
    "thu": 4,
    "fri": 5,
    "sat": 6,
    "sun": 7
  }

  var dayNames = {
    mon: "Montag",
    tue: "Dienstag",
    wed: "Mittwoch",
    thu: "Donnerstag",
    fri: "Freitag",
    sat: "Samstag",
    sun: "Sonntag"
  };

  let weekday = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][new Date().getDay()]

  var dayKeys = Object.keys(openingHours);
  var len = dayKeys.length;

  dayKeys.sort(function sortByDay(a, b) {
    let day1 = a.toLowerCase();
    let day2 = b.toLowerCase();
    return sorter[day1] - sorter[day2];
  });

  for (var i = 0; i < len; i++) {
    var k = dayKeys[i];
    sortedOpeningHours[k] = openingHours[k];
  }

  Object.keys(sortedOpeningHours).map(function(day, index)
  {
    var isSomewhereTrue = false;

    for (var i = 0; i < printOpeningHoursArray.length; i++)
    {
      if( JSON.stringify(printOpeningHoursArray[i].opening) === JSON.stringify(openingHours[day])
          && (sorter[day] - 1) === sorter[printOpeningHoursArray[i].days[printOpeningHoursArray[i].days.length-1]])
      {
        printOpeningHoursArray[i].days.push(day);
        isSomewhereTrue = true;
      }
    }


    if(isSomewhereTrue === false)
    {
      var tempArray = {
        opening: {},
        days: [],
      }

      tempArray.opening = openingHours[day];
      tempArray.days.push(day);

      printOpeningHoursArray.push(tempArray)
    }

    return true;
  });

  return (
    <div style={{ right: pxOut+'px' }} className="pop-out-info">
      <div className="pop-out-info-tab">
        <Tabs
          value={value}
          onChange={handleChange}
          orientation="vertical"
        >
          <Tab {...valProps(1)} style={{ width: '50px', position: 'relative', float: 'left', }} icon={<PhoneIcon  />} aria-label="favorite" />
          <Tab {...valProps(2)} style={{ width: '50px', position: 'relative', float: 'left', }} icon={<RoomIcon />} aria-label="person" />
          <Tab {...valProps(0)} style={{ width: '50px', position: 'relative', float: 'left', }} icon={<AccessTimeIcon />} aria-label="phone" />
        </Tabs>
      </div>
      <div className="pop-out-info-tabpanel">
        <TabPanel value={value} index={0}>
          <div className="pop-out-info-tab-title">Kontakt</div>
          <div className="pop-out-info-tab-contact" dangerouslySetInnerHTML={{__html: popUpcontactText}} ></div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="pop-out-info-tab-title">Standort / Route</div>
          <div className="pop-out-info-tab-maps-text" dangerouslySetInnerHTML={{__html: mapsLinkText}}></div>
          <div className="pop-out-info-tab-maps">
            <a rel="noreferrer" target='_blank' href={mapsLink}>
              <Button variant="outlined" size="large" startIcon={<Icon>
                                                      <img alt="Google Maps Logo" src={GoogleMaps}/>
                                                    </Icon >}>
                GoogleMaps
              </Button>
            </a>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="pop-out-info-tab-title">Öffnungszeiten</div>
        { printOpeningHoursArray.map((openingHours, index) => {
          return (
            <>
              <div key={index} className="popout-info-time">
                <div>
                {
                  openingHours.days.map(function(dayName, index){
                    return (
                      <div  key={index} style={{ fontFamily: (weekday === dayName ? 'TitilliumBold' : 'Titillium' ) }} className="popout-info-time-name">
                      {dayNames[dayName]}
                      </div>
                    )
                  })
                }
                </div>
                <div className="popout-info-time-time">
                {
                  openingHours.opening.map((openingTimes, index) => {

                    return (
                      <>
                        {convertToTime(openingTimes[0])} - {convertToTime(openingTimes[1] - 0.5)}
                        { openingHours.opening.length === (index + 1) ?
                          null
                          :
                          <div key={index} className="popout-info-time-and">und</div>
                        }
                      </>
                    )

                  })
                }
                { openingHours.opening.length === 0 ?
                  <div style={{ color:'#f76300', }}  className="popout-info-time-time">
                  Geschlossen
                  </div>
                  :
                  null
                }
                </div>
              </div>
              { printOpeningHoursArray.length === (index +1) ?
                null
                :
                <div className="popout-info-time-break"></div>
              }

            </>
          )
         })}
        </TabPanel>
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
