import React from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

export default function PartnerSection(props) {

  return (
    <>
      <div className="grid-section">
        <GridContainer justify="center" align="center">
          <GridItem xs={12} sm={12} md={12}>
            <h2 className="grid-title">Unsere Partner</h2>
          </GridItem>
          <GridItem xs={10} sm={8} md={6}>
            <div className="grid-partner" dangerouslySetInnerHTML={{__html: props.partnerText}}></div>
          </GridItem>
        </GridContainer>
      </div>
    </>
  );
}
