import React from 'react';
import SecuredZone from '../SecuredZone/SecuredZone';
import Dialog from '@material-ui/core/Dialog'
import API from '../../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import AdminServicesDialog from '../../../dialogs/admin-services/admin-services';

const garageApi = new API();

/*{moment(row.created).format('DD.MM.YYYY HH:mm')}*/

class AdminServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      adminServicesDialog: false,
      services: [],
      editService: {},
    };
  }

  componentDidMount()
  {
    this.loadServices();
  }

  loadServices()
  {
    garageApi.endpoints.services.getAll()
      .then((getRequest) => {
        try
        {
          this.setState({ services:  getRequest.data.results });
          this.setState({ loading: false });
        }
        catch(e)
        {
          console.log(e);
        }
      });
  }

  deleteService(unikey)
  {
    var deleteService = this.state.services.find((element) => { return element.unikey === unikey; })

    this.setState({ loading: true });

    garageApi.endpoints.services.delete( {id: deleteService.id })
      .then((getRequest) => {
        try
        {
          this.loadServices();
        }
        catch(e)
        {
          console.log(e);
        }
      });
  }

  closeAdminServicesDialog()
  {
    console.log('close');
    this.setState({ adminServicesDialog: false });
    this.setState({ editService: {} });
  }

  submitAdminServicesDialog(mode,service)
  {
    this.setState({ loading: true });
    if(mode)
    {
      garageApi.endpoints.services.create(service)
        .then((getRequest) => {
          try
          {
            this.loadServices();
          }
          catch(e)
          {
            console.log(e);
          }
        });
    }
    else
    {
      garageApi.endpoints.services.update(service)
        .then((getRequest) => {
          try
          {
            this.loadServices();
          }
          catch(e)
          {
            console.log(e);
          }
        });
    }
    this.setState({ adminServicesDialog: false });
    this.setState({ editService: {} });

  }

  editService(unikey)
  {
    var editService = this.state.services.find((element) => { return element.unikey === unikey; })
    this.setState({ editService: editService });
    this.setState({ adminServicesDialog: true });
  }

  createService()
  {
    this.setState({ adminServicesDialog: true });
  }

  render() {
    return (
      <SecuredZone
        breadcrumbs={{ '/admin/': 'Dashboard','/admin/services': 'Dienstleistungen' }}
        menu={{ 'Dienstleistung Hinzufügen': this.createService.bind(this)}}
        title="Dienstleistungen"
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
      >
        <Dialog open={this.state.adminServicesDialog} onClose={this.closeAdminServicesDialog.bind(this)}>
          <AdminServicesDialog servicesData={this.state.editService} close={this.closeAdminServicesDialog.bind(this)} submit={this.submitAdminServicesDialog.bind(this)}/>
        </Dialog>
        { this.state.loading ?
          <span></span>
          :
          <>
            <div className="settings-title">Dienstleistungen</div>
            <TableContainer className="settings-table" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Titel</TableCell>
                    <TableCell>Beschreibung</TableCell>
                    <TableCell width={22} align="left"></TableCell>
                    <TableCell width={22} align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { Array.isArray(this.state.services) ?
                    <>
                      {this.state.services.map((row) => (
                        <TableRow key={row.unikey}>
                          <TableCell component="th" scope="row">{row.title}</TableCell>
                          <TableCell component="th" scope="row">{row.description}</TableCell>
                          <TableCell size="small">
                            <div className="table-buttons">
                              <IconButton onClick={() => {this.editService(row.unikey)}} size="small">
                                  <EditIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </TableCell>
                          <TableCell size="small">
                            <div className="table-buttons">
                              <IconButton onClick={() => {this.deleteService(row.unikey)}} size="small">
                                  <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                    :
                    <>
                    </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
        }
      </SecuredZone>
    );
  }
}


export default AdminServices;
