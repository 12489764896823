import React, { useState, useEffect } from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import { ServiceExtendedArea } from "../../../components/ServiceArea/ServiceArea.js";
import Skeleton from '@material-ui/lab/Skeleton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as Scroll from 'react-scroll';
//api
import API from '../../../api/api';

const garageApi = new API();

var scroller = Scroll.scroller;

export default function ServicesSection(props) {

  const [services, setServices] = useState({});
  const [snackBar, setSnackBar] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);


  useEffect(() => {

    garageApi.openEndpoints.services.getAll()
      .then((servicesGET) => {
        try
        {
          setServices(servicesGET.data.results);
          setLoadingServices(true);

          if(props.scrollTo !== '')
          {
            scroller.scrollTo(props.scrollTo, {
              duration: 500,
              delay: 50,
              smooth: true,
              offset: -100, // Scrolls to element + 50 pixels down the page
            })
          }
        }
        catch(e)
        {
          setSnackBar(true);
        }
      });
  // eslint-disable-next-line
  }, []);

  function closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  }

  return (
    <>
      <Snackbar open={snackBar} autoHideDuration={6000} onClose={closeSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={closeSnackBar} severity="warning" >Server Fehler 500</MuiAlert>
      </Snackbar>
      <div className="grid-section">

        <div className="mouse-scroll">
          <div className="mouse">
            <div className="mouse-in"></div>
          </div>
          <div>
              <span className="down-arrow-1"></span>
              <span className="down-arrow-2"></span>
              <span className="down-arrow-3"></span>
           </div>
        </div>
        <GridContainer style={{ marginTop: '100px', marginBottom: '50px'}}>

          { loadingServices ?
            <>
              { services.map(function(post, index){
                return (
                  <GridItem xs={12} sm={12} md={12}>
                    <ServiceExtendedArea
                      index={index}
                      title={post.title}
                      description={post.description}
                      text={post.text}
                      image={post.image.image['400']}
                      unikey={post.unikey}
                    />
                  </GridItem>
                )
               })}
             </>
            :
            Array.from({ length: 3 }, (_, i) =>
              <GridItem xs={12} sm={6} md={4}>
                <ServiceExtendedArea
                  title={<Skeleton variant="text" width={'100%'} />}
                  description=<Skeleton variant="rect" width={'100%'} height={118} />
                  image= <Skeleton variant="rect" width={'100%'} height={200} />
                  key=''
                />
              </GridItem>
            )
          }
        </GridContainer>
      </div>
    </>
  );
}
