import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer"
import ParallaxCache from "../../components/Parallax/ParallaxCache";
import { ParallaxBanner  } from 'react-scroll-parallax';
import { useLocation } from 'react-router-dom'
import OnlineTerminSection from "./Sections/OnlineTerminSection";
import {Helmet} from "react-helmet";

export default function OnlineTermin(props) {
  const location = useLocation();
  const { configuration } = props;

  return (
    <div>
      <Helmet>
          <title>Matta-Carrosserie - Online-Termin</title>
      </Helmet>
      <Header
        color="transparent"
        fixed
        currentLocation={location.pathname}
      />
      <div className="page">
        <ParallaxBanner
            className="open-parralax-container"
            layers={[
                {
                    image: ( configuration.appointmentImage ? configuration.appointmentImage.image.header  : ''),
                    amount: 0.5,
                }
            ]}
            style={{
                height: '100%',
            }}
        >
            <div className="open-parralax">
              <div className="open-parralax-content">
                <div className="open-parralax-content-title">Online Termin</div>
              </div>
            </div>
            <ParallaxCache />
        </ParallaxBanner>
        <div className="page-content">
          <OnlineTerminSection
            appointmentSuggestionText={configuration.appointmentSuggestionText}
            appointmentSummaryText={configuration.appointmentSummaryText}
            appointmentThanksText={configuration.appointmentThanksText}
          />
        </div>
        <Footer footerText={configuration.footerText} partnerText={configuration.partnerText} />
      </div>
    </div>
  );
}
