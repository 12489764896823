import React, { Component } from 'react';
import SecuredZone from '../SecuredZone/SecuredZone';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment'

class AdminDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingLocations: true,
      auth: {
        username: '',
        first_name: '',
        last_name: '',
        pk:'',
      },
      panels: [{
        panel: {
          id: '',
          unikey: '',
          created: '',
          spec: {},
        },
      }],
      locations:{},
    };
  }

  componentDidMount() {
    /*panelixApi.endpoints.me.getAll()
      .then((userGet) => {
        this.setState({ auth:  userGet });
        this.setState({ loading: false });
      });

    panelixApi.endpoints.panels.getAll()
      .then((panelGet) => {
        this.setState({ panels:  panelGet.data.results });

        var locationsArray = [];

        panelGet.data.results.map(function(d)
        {
          var thisloc = {};
          thisloc.latLng = [d.spec.location.lat, d.spec.location.long];
          thisloc.name = d.spec.name;
          locationsArray.push(thisloc);
          return true;
        });

        this.setState({ locations: locationsArray });
        this.setState({ loadingLocations: false });
      });*/

      this.setState({ loading: false });
  }

  render() {
    return (
      <SecuredZone breadcrumbs={{ '/admin/': 'Dashboard' }} title="Dashboard" loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }>
        <div className="dashboard-hello">
          <div className="dashboard-hello-text">
          { moment().format('H') < 12 ?
            <>Guten Morgen </>
          :
            <>Guten Tag </>
          }
          {this.state.auth.first_name} 👋
          </div>
        </div>
        { this.state.loadingLocations ?
          <span></span>
          :
          <span></span>
        }
        { this.state.loadingLocations ?
          <span></span>
          :
          <span></span>
        }
      </SecuredZone>
    );
  }
}


export default AdminDashboard;
