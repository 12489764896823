import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer"
import ParallaxCache from "../../components/Parallax/ParallaxCache";
import { ParallaxBanner  } from 'react-scroll-parallax';
import { useLocation } from 'react-router-dom'
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import {Helmet} from "react-helmet";

export default function DataProtection(props) {
  const location = useLocation();
  const { configuration } = props;

  return (
    <div>
      <Helmet>
          <title>Matta-Carrosserie - Datenschutz</title>
      </Helmet>
      <Header
        color="transparent"
        fixed
        currentLocation={location.pathname}
      />
      <div className="page">
        <ParallaxBanner
            className="open-parralax-container"
            layers={[
                {
                    image: ( configuration.dataProtectionImage ? configuration.dataProtectionImage.image.header  : ''),
                    amount: 0.5,
                }
            ]}
            style={{
                height: '100%',
            }}
        >
            <div className="open-parralax">
              <div className="open-parralax-content">
                <div className="open-parralax-content-title">Datenschutz</div>
              </div>
            </div>
            <ParallaxCache />
        </ParallaxBanner>
        <div className="page-content">
          <div className="grid-section">

            <div className="mouse-scroll">
              <div className="mouse">
                <div className="mouse-in"></div>
              </div>
              <div>
                  <span className="down-arrow-1"></span>
                  <span className="down-arrow-2"></span>
                  <span className="down-arrow-3"></span>
               </div>
            </div>
            <GridContainer justify="center">
              <GridItem style={{ marginTop: '30px', textAlign:'left' }} xs={12} sm={12} md={12}>
                <h2 className="grid-title">Datenschutz Erklährung</h2>
                <div  dangerouslySetInnerHTML={{__html: configuration.dataProtectionText}}></div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer footerText={configuration.footerText} partnerText={configuration.partnerText} />
      </div>
    </div>
  );
}
