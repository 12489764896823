import { useLayoutEffect } from "react";
import { useController  } from 'react-scroll-parallax';

export default function ParallaxCache() {
  const { parallaxController } = useController();

    useLayoutEffect(() => {
        const handler = () => parallaxController.update();
        window.addEventListener('scroll', handler);
        return () => window.removeEventListener('scroll', handler);
    }, [parallaxController]);

    return null;
};
