import React from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import OnlineTerminList from "../../../components/OnlineTerminList/OnlineTerminList.js";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import linkState from 'linkstate';
import DateFnsUtils from '@date-io/date-fns';
 // eslint-disable-next-line
import { format } from 'date-fns'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Verify from "../../../components/Verify/Verify.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import * as Scroll from 'react-scroll';
//api
import API from '../../../api/api';

const garageApi = new API();

var scroller = Scroll.scroller;

class OnlineTerminSection extends React.Component {

  constructor(props)
  {
    super(props);

    const currentDate = new Date();
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 1);

    this.state = {
      values: {
        firstname: '',
        secondname: '',
        emailAddress: '',
        phoneNumber: '',
        carMake: '',
        carModel: '',
        checks: {},
        services: {},
        dateTime: currentDate,
        printOut: '',
      },
      validate: {
        firstname: false,
        secondname: false,
        emailAddress: false,
        phoneNumber: false,
        carMake: false,
        carModel: false,
      },
      maximumDate: maxDate,
      sent: false,
      snackBar: false,
      services: {},
      checks: {},
      loadingServices: true,
      loadingChecks: true,
      activeStep: 0,
      steps: ['Kunden Daten', 'Checks', 'Dienstleistungen', 'Termin Vorschlag', 'Zusammenfassung'],
      verifyDialog: false,
    };

    this.sendHTML = React.createRef();

    garageApi.openEndpoints.appointmentServices.getAll()
      .then((servicesGET) => {
        try
        {
          this.setState({ services: servicesGET.data.results});
          this.setState({ loadingServices: false});

          servicesGET.data.results.map((label, index) =>
          {
            // eslint-disable-next-line
            this.state.values.services[label.unikey] = false;
            return true;
          })
        }
        catch(e)
        {
          this.setState({ snackBar:true })
        }

      });

    garageApi.openEndpoints.appointmentChecks.getAll()
      .then((servicesGET) => {
        try
        {
          this.setState({ checks: servicesGET.data.results});
          this.setState({ loadingChecks: false});

          servicesGET.data.results.map((label, index) =>
          {
            // eslint-disable-next-line
            this.state.values.checks[label.unikey] = false;
            return true;
          })
        }
        catch(e)
        {
          this.setState({ snackBar:true })
        }

      });
  }

  send()
  {
    this.setState({ verifyDialog:true })
  }

  handleNext() {
    if(this.checkValidity())
    {
      var currentStep = this.state.activeStep;

      this.setState(prevState => {
         return {activeStep: prevState.activeStep + 1}
      })

      setTimeout(() => {
        scroller.scrollTo((currentStep + 2), {
          duration: 500,
          delay: 0,
          smooth: true,
          offset: -100,
        })
      }, 700);

    }
    else {

    }
  };

  checkValidity()
  {
    var ret = true;
    // eslint-disable-next-line
    this.state.validate.firstname = false;
    // eslint-disable-next-line
    this.state.validate.secondname = false;
    // eslint-disable-next-line
    this.state.validate.emailAddress = false;
    // eslint-disable-next-line
    this.state.validate.phoneNumber = false;
    // eslint-disable-next-line
    this.state.validate.carMake = false;
    // eslint-disable-next-line
    this.state.validate.carModel = false;

    if(this.state.values.firstname === "") {
      ret = false;
      // eslint-disable-next-line
      this.state.validate.firstname = true;
    }

    if (this.state.values.secondname === "") {
      ret = false;
      // eslint-disable-next-line
      this.state.validate.secondname = true;
    }

    if (this.state.values.emailAddress === "") {
      ret = false;
      // eslint-disable-next-line
      this.state.validate.emailAddress = true;
    }

    if (this.state.values.phoneNumber === "") {
      ret = false;
      // eslint-disable-next-line
      this.state.validate.phoneNumber = true;
    }

    if (this.state.values.carMake === "") {
      ret = false;
      // eslint-disable-next-line
      this.state.validate.carMake = true;
    }

    if (this.state.values.carModel === "") {
      ret = false;
      // eslint-disable-next-line
      this.state.validate.carModel = true;
    }

    this.setState({ sent:false })

    return ret;
  }

  handleBack() {

    var currentStep = this.state.activeStep;

    this.setState(prevState => {
       return {activeStep: prevState.activeStep - 1}
    })

    setTimeout(() => {
      scroller.scrollTo((currentStep), {
        duration: 500,
        delay: 0,
        smooth: true,
        offset: -100,
      })
    }, 700);
  };

  handleReset() {
    this.setState({ activeStep: 0});
  };

  closeVerifyDialog(result)
  {
    if(result)
    {
      var services = [];
      var checks = [];
      var values = { ...this.state.values };

      Object.keys(this.state.values.checks).map((key) =>
      {
        var check = this.state.checks.find(check => check.unikey === key);
        checks.push(check.id);
        return true;
      });

      Object.keys(this.state.values.services).map((key) =>
      {
        var service = this.state.services.find(service => service.unikey === key);
        services.push(service.id);
        return true;
      });

      values.printOut = this.sendHTML.current.innerHTML;

      values.checks = checks;
      values.services = services;

      garageApi.openEndpoints.formAppointment.create(values)
        .then((resultGET) => {
          try
          {
            this.setState({ sent:true })
          }
          catch(e)
          {
            this.setState({ snackBar:true })
          }
        });
    }
    this.setState({ verifyDialog:false })
  }

  closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackBar:false })
  }

  render() {
    return (
      <>
        <Snackbar open={this.state.snackBar} autoHideDuration={6000} onClose={this.closeSnackBar.bind(this)}>
          <MuiAlert elevation={6} variant="filled" onClose={this.closeSnackBar.bind(this)} severity="warning" >Server Fehler 500</MuiAlert>
        </Snackbar>
        <Verify open={this.state.verifyDialog} close={this.closeVerifyDialog.bind(this)}/>
        <div className="grid-section">

          <div className="mouse-scroll">
            <div className="mouse">
              <div className="mouse-in"></div>
            </div>
            <div>
                <span className="down-arrow-1"></span>
                <span className="down-arrow-2"></span>
                <span className="down-arrow-3"></span>
             </div>
          </div>
          <GridContainer spacing={0} justify="center">
            <GridItem xs={12} sm={8} md={6}>
              <h2 className="grid-title">Termin Formular</h2>
            </GridItem>
          </GridContainer>
          <div style={{ marginTop: '60px', marginBottom: '50px'}}>
            <Stepper style={{ width: '100%' }} activeStep={this.state.activeStep} orientation="vertical">
              {this.state.steps.map((label, index) => (
                <Step id={index+1} key={label}>
                  <StepLabel className="online-termin-stepper" >{label}</StepLabel>
                  <StepContent>
                    <Typography>
                    {{
                      0: <div className="online-termin-container">
                          <div className="online-termin-data">

                          <GridContainer justify="center">
                            <GridItem xs={12} sm={10} md={4}>
                              <TextField
                                label="Vorname"
                                fullWidth
                                error={this.state.validate.firstname}
                                style={{
                                  marginBottom: '20px',
                                }}
                                value={this.state.values.firstname}
                                onInput={linkState(this, 'values.firstname')}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={10} md={4}>
                              <TextField
                                label="Nachname"
                                fullWidth
                                error={this.state.validate.secondname}
                                style={{
                                  marginBottom: '20px',
                                }}
                                value={this.state.values.secondname}
                                onInput={linkState(this, 'values.secondname')}
                              />
                            </GridItem>
                          </GridContainer>

                          <GridContainer justify="center">
                            <GridItem xs={12} sm={10} md={4}>
                              <TextField
                                label="E-Mail Adresse"
                                fullWidth
                                error={this.state.validate.emailAddress}
                                style={{
                                  marginBottom: '20px',
                                }}
                                value={this.state.values.emailAddress}
                                onInput={linkState(this, 'values.emailAddress')}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={10} md={4}>
                              <TextField
                                label="Telefonnummer"
                                fullWidth
                                error={this.state.validate.phoneNumber}
                                style={{
                                  marginBottom: '20px',
                                }}
                                value={this.state.values.phoneNumber}
                                onInput={linkState(this, 'values.phoneNumber')}
                              />
                            </GridItem>
                          </GridContainer>

                          <GridContainer justify="center">
                            <GridItem xs={12} sm={10} md={4}>
                              <TextField
                                label="Auto Marke"
                                fullWidth
                                error={this.state.validate.carMake}
                                style={{
                                  marginBottom: '20px',
                                }}
                                value={this.state.values.carMake}
                                onInput={linkState(this, 'values.carMake')}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={10} md={4}>
                              <TextField
                                label="Auto Modell"
                                fullWidth
                                error={this.state.validate.carModel}
                                style={{
                                  marginBottom: '20px',
                                }}
                                value={this.state.values.carModel}
                                onInput={linkState(this, 'values.carModel')}
                              />
                            </GridItem>
                          </GridContainer>

                          </div>
                          <div className="online-termin-button">
                            <span></span>
                            <span></span>
                            <Button variant="outlined" onClick={this.handleNext.bind(this)}>Next</Button>
                          </div>
                      </div>,
                      1: <div className="online-termin-container">
                          { this.state.loadingChecks ?
                           <></>
                           :
                           <>
                             <div className="online-termin-data">
                               { this.state.checks.map((label, index) => {

                                 return (
                                   <div>
                                     <OnlineTerminList
                                       key={label.unikey}
                                       title={label.title}
                                       description={label.description}
                                       image={label.image.image['200']}
                                       checkbox=<Checkbox onChange={linkState(this, `values.checks.${label.unikey}`)} checked={this.state.values.checks[label.unikey]} name={label.unikey} />
                                       unikey={label.unikey}
                                     />
                                   </div>
                                 )}
                              )}
                             </div>
                             <div className="online-termin-button">
                               <Button variant="outlined" onClick={this.handleBack.bind(this)}>Zurück</Button>
                               <span></span>
                               <Button variant="outlined" onClick={this.handleNext.bind(this)}>Weiter</Button>
                             </div>
                           </>
                          }
                      </div>,
                      2: <div className="online-termin-container">
                          { this.state.loadingServices ?
                           <></>
                           :
                           <>
                             <div className="online-termin-data">
                               { this.state.services.map((label, index) => (

                                 <div>
                                   <OnlineTerminList
                                     key={label.unikey}
                                     title={label.title}
                                     description={label.description}
                                     image={label.image.image['200']}
                                     checkbox=<Checkbox onChange={linkState(this, `values.services.${label.unikey}`)} checked={this.state.values.services[label.unikey]} name={label.unikey} />
                                     unikey={label.unikey}
                                   />
                                 </div>

                                 ))
                               }
                             </div>
                             <div className="online-termin-button">
                               <Button variant="outlined" onClick={this.handleBack.bind(this)}>Zurück</Button>
                               <span></span>
                               <Button variant="outlined" onClick={this.handleNext.bind(this)}>Weiter</Button>
                             </div>
                           </>
                          }
                      </div>,
                      3: <div className="online-termin-container">
                          <div className="online-termin-data">

                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                              <div className="online-termin-date-text" dangerouslySetInnerHTML={{__html: this.props.appointmentSuggestionText}}>
                              </div>
                            </GridItem>
                          </GridContainer>

                          <GridContainer justify="center">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <GridItem style={{ marginTop: '30px',marginBottom: '30px' }} xs={12} sm={12} md={6}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  format="dd.MM.yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Datum"
                                  minDate={new Date()}
                                  maxDate={this.state.maximumDate}
                                  value={this.state.values.dateTime}
                                  onChange={linkState(this, `values.dateTime`)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'Datum Auswählen',
                                  }}
                                />
                              </GridItem>
                              <GridItem style={{ marginTop: '30px',marginBottom: '30px' }} xs={12} sm={12} md={6}>
                                <KeyboardTimePicker
                                  disableToolbar
                                  margin="normal"
                                  variant="inline"
                                  id="time-picker"
                                  ampm={false}
                                  label="Uhrzeit"
                                  value={this.state.values.dateTime}
                                  onChange={linkState(this, `values.dateTime`)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'Zeit Auswählen',
                                  }}
                                />
                                </GridItem>
                             </MuiPickersUtilsProvider>
                            </GridContainer>
                          </div>
                          <div className="online-termin-button">
                            <Button variant="outlined" onClick={this.handleBack.bind(this)}>Zurück</Button>
                            <span></span>
                            <Button variant="outlined" onClick={this.handleNext.bind(this)}>Weiter</Button>
                          </div>
                      </div>,
                      4: <div className="online-termin-container">
                          <div className="online-termin-data">
                          <GridContainer justify="center">
                            <GridItem style={{ marginBottom: '30px' }} xs={12} sm={12} md={12}>
                              <div className="online-termin-date-text" dangerouslySetInnerHTML={{__html: this.props.appointmentSummaryText}}>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={8} md={6}>
                              <TableContainer ref={this.sendHTML}>
                                <Table aria-label="simple table">
                                  <TableBody>
                                    <TableRow key='name'>
                                      <TableCell component="th" scope="row">Name</TableCell>
                                      <TableCell align="left">{this.state.values.firstname} {this.state.values.secondname}</TableCell>
                                    </TableRow>
                                    <TableRow key='email'>
                                      <TableCell component="th" scope="row">E-Mail Adresse</TableCell>
                                      <TableCell align="left">{this.state.values.emailAddress}</TableCell>
                                    </TableRow>
                                    <TableRow key='tel'>
                                      <TableCell component="th" scope="row">Telefonnumer</TableCell>
                                      <TableCell align="left">{this.state.values.phoneNumber}</TableCell>
                                    </TableRow>
                                    <TableRow key='car'>
                                      <TableCell component="th" scope="row">Auto</TableCell>
                                      <TableCell align="left">{this.state.values.carMake} {this.state.values.carModel}</TableCell>
                                    </TableRow>
                                    <TableRow key='checks'>
                                      <TableCell component="th" scope="row">Checks</TableCell>
                                      { (Object.keys(this.state.values.checks).length >=1) ?
                                        <TableCell align="left">
                                        { Object.entries(this.state.values.checks).map(([key, val], i) => {

                                          if(val)
                                          {
                                            var currentCheck = this.state.checks.find(check => check.unikey === key);
                                            return (
                                              <p>{currentCheck.title}</p>
                                            )
                                          }
                                          else {
                                              return (<></>);
                                            }
                                        }
                                       )}
                                        </TableCell>
                                        :
                                        <TableCell align="left">Keine</TableCell>
                                      }
                                    </TableRow>
                                    <TableRow key='services'>
                                      <TableCell component="th" scope="row">Dienstleistungen</TableCell>
                                      { (Object.keys(this.state.values.services).length >=1) ?
                                        <TableCell align="left">
                                        { Object.entries(this.state.values.services).map(([key, val], i) => {

                                          if(val)
                                          {
                                            var currentService = this.state.services.find(service => service.unikey === key);
                                            return (
                                              <p>{currentService.title}</p>
                                            )
                                          }
                                          else {
                                              return (<></>);
                                            }
                                        }
                                       )}
                                        </TableCell>
                                        :
                                        <TableCell align="left">Keine</TableCell>
                                      }
                                    </TableRow>
                                    <TableRow key='car'>
                                      <TableCell component="th" scope="row">Datum und Zeit</TableCell>
                                      <TableCell align="left">{moment(this.state.values.dateTime).format('DD.MM.YYYY HH:mm')}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </GridItem>
                          </GridContainer>
                          </div>
                          { this.state.sent ?
                            <>
                            </>
                            :
                            <div className="online-termin-button">
                              <Button variant="outlined" onClick={this.handleBack.bind(this)}>Zurück</Button>
                              <span></span>
                              <Button variant="outlined" onClick={this.send.bind(this)}>Absenden</Button>
                            </div>
                          }
                      </div>,
                    }[index]}
                    </Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            { this.state.sent ?
              <GridContainer justify="center">
                <GridItem style={{ marginTop: '30px' }} xs={12} sm={12} md={12}>
                  <div className="online-termin-date-text"  dangerouslySetInnerHTML={{__html: this.props.appointmentThanksText}}>
                  </div>
                </GridItem>
              </GridContainer>
              :
              <></>
           }
          </div>
        </div>
      </>
    );
  }
}


export default OnlineTerminSection;
