import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
        secondary: {
          light: '#14a0db',
          main: '#14a0db',
          dark: '#14a0db',
          contrastText: '#14a0db',
        },
  },
  overrides: {
    MuiToolbar: {
        regular: {
          minHeight: "80px",
          '@media (min-width: 600px)': {
            minHeight: "80px"
          }
        },
    },
  }
});

export default theme;
