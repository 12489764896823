import React from 'react';
import SecuredZone from '../SecuredZone/SecuredZone';
import Dialog from '@material-ui/core/Dialog'
import API from '../../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import AdminEmployeesDialog from '../../../dialogs/admin-employees/admin-employees';

const garageApi = new API();

/*{moment(row.created).format('DD.MM.YYYY HH:mm')}*/

class AdminEmployees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      adminEmployeesDialog: false,
      employees: [],
      editEmployee: {},
    };
  }

  componentDidMount()
  {
    this.loadEmployees();
  }

  loadEmployees()
  {
    garageApi.endpoints.employees.getAll()
      .then((getRequest) => {
        try
        {
          this.setState({ employees:  getRequest.data.results });
          this.setState({ loading: false });
        }
        catch(e)
        {
          console.log(e);
        }
      });
  }

  deleteEmployee(unikey)
  {
    var deleteEmployee = this.state.employees.find((element) => { return element.unikey === unikey; })

    this.setState({ loading: true });

    garageApi.endpoints.employees.delete( {id: deleteEmployee.id })
      .then((getRequest) => {
        try
        {
          this.loadEmployees();
        }
        catch(e)
        {
          console.log(e);
        }
      });
  }

  closeAdminEmployeesDialog()
  {
    console.log('close');
    this.setState({ adminEmployeesDialog: false });
    this.setState({ editEmployee: {} });
  }

  submitAdminEmployeesDialog(mode,user)
  {
    this.setState({ loading: true });
    if(mode)
    {
      garageApi.endpoints.employees.create(user)
        .then((getRequest) => {
          try
          {
            this.loadEmployees();
          }
          catch(e)
          {
            console.log(e);
          }
        });
    }
    else
    {
      garageApi.endpoints.employees.update(user)
        .then((getRequest) => {
          try
          {
            this.loadEmployees();
          }
          catch(e)
          {
            console.log(e);
          }
        });
    }
    this.setState({ adminEmployeesDialog: false });
    this.setState({ editEmployee: {} });

  }

  editEmployee(unikey)
  {
    var editEmployee = this.state.employees.find((element) => { return element.unikey === unikey; })
    this.setState({ editEmployee: editEmployee });
    this.setState({ adminEmployeesDialog: true });
  }

  createEmployee()
  {
    this.setState({ adminEmployeesDialog: true });
  }

  render() {
    return (
      <SecuredZone
        breadcrumbs={{ '/admin/': 'Dashboard','/admin/employees': 'Mitarbeiter' }}
        menu={{ 'Mitarbeiter Hinzufügen': this.createEmployee.bind(this)}}
        title="Mitarbeiter"
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
      >
        <Dialog open={this.state.adminEmployeesDialog} onClose={this.closeAdminEmployeesDialog.bind(this)}>
          <AdminEmployeesDialog employeesData={this.state.editEmployee} close={this.closeAdminEmployeesDialog.bind(this)} submit={this.submitAdminEmployeesDialog.bind(this)}/>
        </Dialog>
        { this.state.loading ?
          <span></span>
          :
          <>
            <div className="settings-title">Mitarbeiter</div>
            <TableContainer className="settings-table" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell>Beruf</TableCell>
                    <TableCell>E-Mail</TableCell>
                    <TableCell>Telefon</TableCell>
                    <TableCell width={22} align="left"></TableCell>
                    <TableCell width={22} align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { Array.isArray(this.state.employees) ?
                    <>
                      {this.state.employees.map((row) => (
                        <TableRow key={row.unikey}>
                          <TableCell component="th" scope="row">{row.name}</TableCell>
                          <TableCell component="th" scope="row">{row.position}</TableCell>
                          <TableCell component="th" scope="row">{row.job}</TableCell>
                          <TableCell component="th" scope="row">{row.email}</TableCell>
                          <TableCell component="th" scope="row">{row.phone}</TableCell>
                          <TableCell size="small">
                            <div className="table-buttons">
                              <IconButton onClick={() => {this.editEmployee(row.unikey)}} size="small">
                                  <EditIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </TableCell>
                          <TableCell size="small">
                            <div className="table-buttons">
                              <IconButton onClick={() => {this.deleteEmployee(row.unikey)}} size="small">
                                  <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                    :
                    <>
                    </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
        }
      </SecuredZone>
    );
  }
}


export default AdminEmployees;
